import React, { useState, useEffect } from 'react';
import {
    Col,
    Row,
    Typography,
    Flex,
    InputNumber,
    Slider
} from "antd";
import Graph from './Graph';

const { Title, Text } = Typography;

/**
 * Simulator
 */
const Simulator = () => {
    const [initialNetWorth, setInitialNetWorth] = useState(100000);
    const [stockWeigth, setStockWeigth] = useState(0.5);
    const [annualInvestment, setAnnualInvestment] = useState(6000);
    const [stockInvestmentWeigth, setStockInvestmentWeigth] = useState(0.5);
    const [horizon, setHorizon] = useState(20);
    const [stockPerformance, setStockPerformance] = useState(0.08);
    const [otherPerformance, setOtherPerformance] = useState(0.05);
    const [stockTaxRate, setStockTaxRate] = useState(0.172);
    const [otherTaxRate, setOtherTaxRate] = useState(0.3);
    const [withdrawalRate, setWithdrawalRate] = useState(0.04);
    const [inflationRate, setInflationRate] = useState(0.03);
    const [wealth, setWealth] = useState({ total: 0, passiveIncome: 0, evolution: [] });

    const computeWealth = () => {
        let netWorth = initialNetWorth;
        let stock = initialNetWorth * stockWeigth;
        let other = initialNetWorth * (1 - stockWeigth);
        let stockInvestment = annualInvestment * stockInvestmentWeigth;
        let otherInvestment = annualInvestment * (1 - stockInvestmentWeigth);
        let passiveIncome = 0;
        let evolution = [];

        for (let i = 0; i < horizon; i++) {
            stock = stock * (1 + stockPerformance - inflationRate) + stockInvestment;
            other = other * (1 + otherPerformance - inflationRate) + otherInvestment;
            netWorth = stock + other;
            evolution.push({
                year: 2024 + i,
                'Patrimoine initial': initialNetWorth,
                'Versements': (evolution.length > 0 ? evolution[i - 1]['Versements'] : 0) + annualInvestment,
                'Intérêts': netWorth - (evolution.length > 0 ? evolution[i - 1]['Versements'] : 0) + annualInvestment - initialNetWorth
            });
        }
        passiveIncome = stock * withdrawalRate * (1 - stockTaxRate) + other * withdrawalRate * (1 - otherTaxRate);
        passiveIncome = passiveIncome / 12;
        return ({
            total: netWorth,
            passiveIncome: passiveIncome,
            evolution: evolution
        });
    };

    useEffect(() => {
        setWealth(computeWealth());
    }, [initialNetWorth, stockWeigth, annualInvestment, stockInvestmentWeigth, horizon, stockPerformance, otherPerformance]);

    return (
        <Flex vertical style={{ outline: 'solid #6E727A', borderRadius: '8px', margin: '40px 10%'}}>
            <Row span={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ padding: '32px' }}>
                    <Row>
                        <Col span={24} align="start">
                            <Text>Patrimoine Actuel</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="EUR"
                                value={initialNetWorth}
                                onChange={(e) => setInitialNetWorth(e)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Répartition de votre patrimoine initial</Text>
                            <div style={{ height: '8px' }} />
                            <Row justify='space-between'>
                                <Text>{(stockWeigth * 100).toFixed(0)} %</Text>
                                <Text>{((1 - stockWeigth) * 100).toFixed(0)} %</Text>
                            </Row>
                            <Slider
                                min={0}
                                max={100}
                                step={1}
                                value={stockWeigth * 100}
                                onChange={(e) => setStockWeigth(e / 100)}
                                railSize="50px"
                            />
                            <Row justify='space-between'>
                                <Text>Bourse</Text>
                                <Text>Autre</Text>
                            </Row>
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Investissements annuels</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="EUR"
                                value={annualInvestment}
                                onChange={(e) => setAnnualInvestment(e)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Répartition des investissements</Text>
                            <div style={{ height: '8px' }} />
                            <Row justify='space-between'>
                                <Text>{(stockInvestmentWeigth * 100).toFixed(0)} %</Text>
                                <Text>{((1 - stockInvestmentWeigth) * 100).toFixed(0)} %</Text>
                            </Row>
                            <Slider
                                min={0}
                                max={100}
                                step={1}
                                value={stockInvestmentWeigth * 100}
                                onChange={(e) => setStockInvestmentWeigth(e / 100)}
                                railSize="50px"
                            />
                            <Row justify='space-between'>
                                <Text>Bourse</Text>
                                <Text>Autre</Text>
                            </Row>
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Nombre d'années d'épargne</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="ANS"
                                value={horizon}
                                onChange={(e) => setHorizon(e)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Rendement bourse</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="%"
                                value={stockPerformance}
                                onChange={(e) => setStockPerformance(e)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Rendement autre</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="%"
                                value={otherPerformance}
                                onChange={(e) => setOtherPerformance(e)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row gutter={16}>
                        <Col span={12} align="start">
                            <Text>Taux d'imposition bourse</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="%"
                                value={stockTaxRate * 100}
                                onChange={(e) => setStockTaxRate(e /100)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                        <Col span={12} align="start">
                            <Text>Taux d'imposition autre</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="%"
                                value={otherTaxRate * 100}
                                onChange={(e) => setOtherTaxRate(e / 100)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Taux de retrait</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="%"
                                value={withdrawalRate * 100}
                                onChange={(e) => setWithdrawalRate(e / 100)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                    <br /><br />
                    <Row>
                        <Col span={24} align="start">
                            <Text>Taux d'inflation</Text>
                            <div style={{ height: '8px' }} />
                            <InputNumber
                                suffix="%"
                                value={inflationRate * 100}
                                onChange={(e) => setInflationRate(e / 100)}
                                style={{ width: '100%' }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{ padding: '32px' }}>
                    <Text style={{ fontSize: 'x-large' }}>
                        Au bout de <b>{horizon} ans</b>, vous pouvez générer un revenu passif de <b>{wealth.passiveIncome.toFixed(0)} €/mois</b> pour un capital final de <b>{wealth.total.toFixed(0)} €</b>
                    </Text>
                    <br />
                    <Graph wealthEvolution={wealth.evolution} />
                </Col>
            </Row>
            <Row justify='center' style={{ margin: '16px' }}>
                <Text>Cet outil est seulement à but informatif. Il ne doit pas être considéré comme un conseil financier.</Text>
            </Row>
        </Flex>
    );
};

export default Simulator;
