import React, { useState, useEffect } from  'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

/**
 * Graph
 * @param {object} wealthEvolution - Object with the wealth evolution data
 */
const Graph = (props) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <AreaChart
            width={screenWidth < 992 ? screenWidth * 0.7 : screenWidth * 0.35}
            height={400}
            data={props.wealthEvolution}
            margin={{
                top: 40,
                right: 0,
                left: 0,
                bottom: 0,
            }}
            >
            <XAxis dataKey="year" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="Patrimoine initial" stackId="1" stroke="#8884d8" fill="#8884d8" />
            <Area type="monotone" dataKey="Versements" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
            <Area type="monotone" dataKey="Intérêts" stackId="1" stroke="#ffc658" fill="#ffc658" />
        </AreaChart>
    );
};

export default Graph;
