import React from 'react';
import { ConfigProvider } from 'antd';
import Hero from './components/Hero';
import Simulator from './components/Simulator';
import Article from './components/Article';
import './App.css';

function App() {
  return (
    <div className="App">
      <ConfigProvider
        theme={{
          token: {
            colorText: '#F6F3D9',
            // Border settings
            colorBorder: '#F6F3D9',
            borderRadius: 2,
            colorBgContainer: '#132924',
          },
        }}
      >
        <Hero />
        <br />
        <Simulator />
        <br />
        <Article />
      </ConfigProvider>
    </div>
  );
}

export default App;
