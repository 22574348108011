import React from 'react';
import { Typography } from "antd";

const { Title, Text } = Typography;

/**
 * Hero
 */
const Hero = () => {
    return (
        <div>
            <Title color='#F6F3D9'>Simulateur de patrimoine</Title>
            <Text>
                Découvrez combien de revenu passif mensuel vous pouvez générer grâce à votre patrimoine et vos investissements.<br />
                Utilisez notre simulateur de patrimoine en ligne en entrant simplement quelques informations.
            </Text>
        </div>
    );
};

export default Hero;
