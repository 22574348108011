import React from 'react';
import { Flex, Typography } from "antd";

const { Title, Text } = Typography;

/**
 * Article
 */
const Article = () => {
    return (
        <Flex vertical style={{ margin: '40px 10%', textAlign: 'left' }}>
            <Title level={2}>Comment utiliser notre simulateur de patrimoine ?</Title>
            <Text>
                Le simulateur de patrimoine est facile à utiliser. Commencez par entrer votre patrimoine actuel et sa répartition entre actifs boursiers et autres types d'actifs (immobilier, crypto, livrets, etc.). Ensuite, indiquez le montant de votre épargne annuelle, sa distribution entre ces catégories d'actifs, la durée de votre épargne, ainsi que les taux de rendement et d'imposition pour chaque classe d'actif. Enfin, précisez le taux de retrait de capital et le taux d'inflation. Un taux de retrait de 4% est souvent recommandé, notamment par le mouvement FIRE.
            </Text>
            <br />
            <Title level={2}>Comprendre les résultats du simulateur</Title>
            <Text>
                Le simulateur vous indique le <b>revenu passif</b> que vous pourrez générer à la fin de votre <b>période d'épargne</b>, appelée aussi période d'accumulation. Ce revenu est calculé en fonction de votre patrimoine et des paramètres que vous avez fournis. Le graphique affiche l'évolution de votre patrimoine au fil du temps, incluant votre patrimoine initial, vos versements, et les intérêts générés.
            </Text>
            <br />
            <Title level={2}>Comment atteindre l'indépendance financière ?</Title>
            <Text>
                L'indépendance financière est un rêve pour beaucoup, mais rares sont ceux qui savent comment l'atteindre. Chez Investly, nous avons conçu un outil simple pour vous aider à préparer votre indépendance financière. Ce simulateur effectue 5 000 simulations basées sur nos algorithmes exclusifs pour estimer l'âge auquel vous atteindrez l'indépendance financière. En se basant sur votre patrimoine réel connecté sur Investly et en tenant compte de l'inflation, Predict vous propose également des stratégies pour optimiser votre progression.
            </Text>
        </Flex>
    );
};

export default Article;
